import { createSlice } from '@reduxjs/toolkit';
// Slice
const slice = createSlice({
  name: 'header',
  initialState: {
    location: { path: [{ title: '', link: null }], action: null },
  },
  reducers: {
    changeLocationSuccess: (state, action) => {
      state.location = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const { changeLocationSuccess } = slice.actions;
export const changeLocation = currentLocation => async dispatch => {
  try {
    dispatch(changeLocationSuccess(currentLocation));
  } catch (e) {
    return console.error(e.message);
  }
};
