import React, { Component } from 'react';
import styled, { keyframes } from '../../styles/styled-components';

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

interface ICircle {
  rotate?: number;
  delay?: number;
  id?: string;
}

export default class Circle extends Component<ICircle> {
  render() {
    const CirclePrimitive = styled.div`
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      ${this.props.rotate &&
      `
        -webkit-transform: rotate(${this.props.rotate}deg);
        -ms-transform: rotate(${this.props.rotate}deg);
        transform: rotate(${this.props.rotate}deg);
      `} &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #999;
        border-radius: 100%;
        animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
        ${this.props.delay &&
        `
          -webkit-animation-delay: ${this.props.delay}s;
          animation-delay: ${this.props.delay}s;
        `};
      }
    `;

    return <CirclePrimitive />;
  }
}
