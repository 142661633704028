export const userHasPermission = (permission: string | undefined, levelNeeded: string | undefined) => {
  // If no permission is needed, let it go
  if (!permission) return true;

  let uString = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'urmetauth');

  if (uString) {
    const { user } = JSON.parse(uString);

    // If the user is admin, let it go
    if (user.isAdmin) return true;
    if(user.isStats && permission == "stats") return true;
    // If a level is explicitly needed, it's a CHMOD-like permission
    if (levelNeeded) {
      // Users with Write permission can Read too
      const needed = levelNeeded === 'W' ? ['W'] : ['R', 'W'];

      // If the user grant the CHMOD-level, let it go
      return needed.includes(user.permissions[permission]);
    }

    // Else it's a basic boolean permission, let it go if he's got the permission
    return user.permissions[permission];
  }

  return false;
};
