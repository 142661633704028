import { createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/context/service';

// Slice
const slice = createSlice({
  name: 'auth',

  initialState: {
    user: null,
    token: null,
  },

  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'urmetauth', JSON.stringify(action.payload));
    },

    logoutSuccess: (state, action) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'urmetauth');
    },

    checkLoginSuccess: (state, action) => {
      let u = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'urmetauth');
      if (u) {
        let r = JSON.parse(u);
        state.user = r.user;
        state.token = r.token;
      } else {
        state.user = null;
        state.token = null;
      }
    },
  },
});

export default slice.reducer;

const { loginSuccess, logoutSuccess, checkLoginSuccess } = slice.actions;

const getTokenParameters = () => {
  // Disable SSO on local and staging environment: if the user is unknown just set a dummy token
  if (['local', 'staging'].includes(process.env.REACT_APP_ENV ?? 'production')) {
    let u = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'urmetauth');

    if (!u) {
      return 'useless';
    }
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('t');
};

export const checkLogin = () => dispatch => {
  try {
    const token = getTokenParameters();

    if (token != null) {
      dispatch(logoutSuccess(null));

      try {
        axiosService.get(`user?token=${token}`).then(response => {
          if (response.status === 200) {
            dispatch(loginSuccess({ token: token, user: response.data }));
            window.location.href = window.location.origin + window.location.pathname;
          } else {
            console.log('error token');
          }
        });
      } catch (e) {
        console.log('error token');
      }
    }

    dispatch(checkLoginSuccess(null));
  } catch (e) {
    return console.error(e.message);
  }
};

export const login = user => dispatch => {
  try {
    dispatch(loginSuccess(user));
  } catch (e) {
    return console.error(e.message);
  }
};

export const logout = () => dispatch => {
  try {
    return dispatch(logoutSuccess(null));
  } catch (e) {
    return console.error(e.message);
  }
};
