import React, { Component } from 'react';
import A from 'app/components/A';
import IconButton from 'app/components/IconButton';
import Search from 'app/components/Search';
import styled from 'styled-components';
import { IoIosArrowDropleftCircle, IoIosLogOut } from 'react-icons/io';
import { connect } from 'react-redux';
import { AiOutlineFileSearch } from 'react-icons/ai';

import eventBus from '../../EventBus';

const HeaderStore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const HeaderCurrentLocation = styled.div`
  color: #0c1635;
  font-weight: 500;
  font-size: 18px;
  a {
    color: #0c1635;
    font-weight: 500;
    font-size: 18px;
  }
  &.padding-left {
    padding-left: 10px;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const mapStateToProps = state => ({
  header: state.header,
});

class Header extends Component {
  state = {
    sidebarOpen: true,
  };

  componentDidUpdate() {
    eventBus.on('sidebarState', data => {
      console.log(this.state.sidebarOpen);
      console.log(data.sidebarOpen);
      this.setState({ sidebarOpen: data.sidebarOpen });
    });
  }

  goBack() {
    window.history.back();
  }

  lastLink(path) {
    var r = null;
    if (path) {
      path.forEach(elm => {
        if (elm.link != null) {
          r = elm.link;
        }
      });
    }
    return r;
  }

  render() {
    const HeaderStyled = styled.div`
      padding: 10px;
      position: fixed;
      width: ${this.state.sidebarOpen ? 'calc(100% - 200px)' : 'calc(100% - 70px)'};
      top: 0px;
      left: auto;
      height: 70px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      z-index: 500;
      border-bottom: 2px solid whitesmoke;
      @media (max-width: 1024px) {
        width: calc(100% - 70px);
        left: 70px;
      }
    `;

    var lastPathlink = this.lastLink(this.props['header']['location']['path']);
    return (
      <HeaderStyled>
        {lastPathlink && (
          <A to={lastPathlink ?? ''}>
            <IconButton
              label=""
              icon={IoIosArrowDropleftCircle({ size: 30, color: '#0c1635' })}
              bgColor="transparent"
              color="#ffffff"
            />
          </A>
        )}

        <HeaderStore>
          <HeaderCurrentLocation className={`${!lastPathlink ? 'padding-left' : ''}`}>
            {this.props['header']['location']['path'] &&
              this.props['header']['location']['path'].map((e, key) => {
                return (
                  <span key={key}>
                    {!e.link && e.title}
                    {e.link && <A to={e.link}>{e.title}</A>}
                    {this.props['header']['location']['path'].length - 1 > key && ' > '}
                  </span>
                );
              })}
          </HeaderCurrentLocation>
          {this.props['header']['location']['action'] && (
            <HeaderActions>{this.props['header']['location']['action']}</HeaderActions>
          )}
        </HeaderStore>

        <Search />
        <A to="/search">
          <IconButton
            label=""
            icon={AiOutlineFileSearch({ size: 30, color: '#0c1635' })}
            bgColor="transparent"
            color="#ffffff"
          />
        </A>
        <A to="/logout">
          <IconButton
            label=""
            icon={IoIosLogOut({ size: 30, color: '#0c1635' })}
            bgColor="transparent"
            color="#ffffff"
          />
        </A>
      </HeaderStyled>
    );
  }
}

export default connect(mapStateToProps)(Header);
