import React, { Component } from 'react';
import styled from 'styled-components';

export const IconButtonStyled = styled.button`
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  min-height: 35px;
  word-break: normal;

  &:hover {
    opacity: 0.7;
  }
`;

export const IconButtonLabelStyled = styled.div`
  &.hasIcon {
    margin-left: 5px;
  }
`;

export interface IIconButton {
  label?: string;
  bgColor?: string;
  color?: string;
  icon?: any;
  opacity?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  marginRight?: string;
  marginLeft?: string;
  onClick?: () => void;
}

export default class IconButton extends Component<IIconButton> {
  render() {
    return (
      <IconButtonStyled
        className={this.props.className}
        type={this.props.type ? this.props.type : 'button'}
        onClick={this.props.onClick}
        style={{ backgroundColor: this.props.bgColor, color: this.props.color, opacity: this.props.opacity, marginRight: this.props.marginRight, marginLeft: this.props.marginLeft}}
      >
        {this.props.icon && <>{this.props.icon}</>}
        {this.props.label && (
          <IconButtonLabelStyled className={`${this.props.icon ? 'hasIcon' : ''}`}>
            {this.props.label}
          </IconButtonLabelStyled>
        )}
      </IconButtonStyled>
    );
  }
}
