import axios from 'axios';
import Swal from 'sweetalert2';

const axiosService = axios;

axiosService.interceptors.request.use(
  function (aconfig) {
    aconfig.baseURL = process.env.REACT_APP_API_URL;

    let token = null;
    let u = localStorage.getItem(process.env.REACT_APP_HTACCESS_PASSWORD ?? 'urmetauth');
    if (u) {
      token = JSON.parse(u).token;
    }

    if (process.env.REACT_APP_HTACCESS_LOGIN && process.env.REACT_APP_HTACCESS_PASSWORD) {
      aconfig.withCredentials = true;
      aconfig.auth = {
        username: process.env.REACT_APP_HTACCESS_LOGIN,
        password: process.env.REACT_APP_HTACCESS_PASSWORD,
      };
    } else {
      if (token) {
        aconfig.headers.common = {
          Authorization: `Bearer ${token}`,
          ...aconfig.headers.common,
        };
      }
    }

    return aconfig;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // TODO: Handle other error's codes
    if (error.response) {
      switch (error.response.status) {
        // 422 is a form validation error, just let the form handle it
        case 422:
          break;
        case 401:
          Swal.fire('Erreur', `Votre authentification a expiré.<br>Merci de vous reconnecter.`, 'warning');
          break;
        case 403:
          Swal.fire(
            'Erreur',
            `Vous n'êtes pas autorisé à effectuer cette action.<br>Veuillez contacter un administrateur.`,
            'warning',
          );
          break;
        default:
          Swal.fire('Erreur', `Une erreur est survenue.<br>${error.response.data.message ?? ''}`, 'error');
      }
    }

    return Promise.reject(error);
  },
);

export default axiosService;
