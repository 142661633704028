import React, { Component } from 'react';
import { userHasPermission } from '../../../utils/permissions';

export interface IPermission {
  tag?: string;
  permission?: string;
  level?: string;
  children: any;
}

export default class Permission extends Component<IPermission> {
  state = {
    display: false,
  };

  componentDidMount() {
    this.setState({ display: userHasPermission(this.props.permission, this.props.level) });
  }

  render() {
    if (!this.state.display) {
      return null;
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
